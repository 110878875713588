<template>
  <div id="graduate" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns">
        <div class="column is-6 is-offset-3">
          <ValidationObserver ref="observer" v-slot="{ passes }">
            <form @submit.prevent="passes(initializeWithdrawal)">
              <SdInput
                type="text"
                rules="required"
                label="Name"
                v-model="student.name"
                disabled
              />

              <SdInput
                type="text"
                rules="required"
                label="School Class"
                v-model="student.schoolClass.name"
                disabled
              />

              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
              >
                Submit
              </button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </template>
    <!-- Delete -->
    <app-modal
      id="graduate-student"
      context="add"
      @add="submit"
      :show-modal.sync="isModalOpen"
    >
      <template slot="add"
        ><p>You want to graduate this student?</p>
        <p>Proceed?</p>
      </template>
    </app-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '@/assets/js/notification'

export default {
  name: 'graduate',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Graduate',
      skipQuery: true,
      student: {},
    }
  },
  apollo: {
    student: {
      query: gql`
        query studentId($id: ID!) {
          student(id: $id) {
            id
            name
            schoolClass {
              id
              name
            }
            termId
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
    },
  },
  watch: {
    student(data) {
      this.schoolClassId = data.schoolClass.id
      this.$store.commit('setSubMenus', [
        {
          name: 'Graduates',
          route: `/school/${this.$route.params.school_id}/graduates`,
        },
        {
          name: 'Graduates Info',
          route: `/school/${this.$route.params.school_id}/graduates_info/${this.student.termId}`,
        },
        {
          name: 'Student Info',
          route: `/school/${this.$route.params.school_id}/school_class/${data.schoolClass.id}/student_info/${this.$route.params.id}`,
        },
      ])
    },
  },
  methods: {
    initializeWithdrawal() {
      this.openModal()
    },
    submit() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation CreateGraduateQuery(
              $termId: Int!
              $schoolClassId: Int!
              $studentId: Int!
            ) {
              createGraduate(
                input: {
                  termId: $termId
                  schoolClassId: $schoolClassId
                  studentId: $studentId
                }
              ) {
                graduate {
                  id
                  termId
                  schoolClassId
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            termId: parseInt(this.student.termId),
            schoolClassId: parseInt(this.student.schoolClass.id),
            studentId: parseInt(this.student.id),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.createGraduate.errors,
            'Successfully created.'
          ).then(() => {
            this.closeModal()
            this.$router.push(
              `/school/${this.schoolId}/students/${this.schoolClassId}`
            )
          })
        })
        .catch((error) => {
          console.error(error.message)
        })
    },
  },
  components: {
    SdInput,
    ValidationObserver,
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
  },
}
</script>

<style lang="scss" scoped></style>
